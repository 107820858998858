
<!-- <div
    style="
      display: flex;
      flex-direction: row;
      gap: 5px;
      position: absolute;
      padding-right: 30px;
      padding-bottom:18px;
      width: 100%;
      align-items: flex-end;
      justify-content: end;
      height: 100%;
      min-width: 1200px;
      "
  >

        <span class="span-text-dark" style="font-size: 16px; ">powered by</span>
        <img src="/assets/logos/bertlabs-logo.png" height="25px" width="100px" />
  
</div> -->
<div
    style="
      display: flex;
      flex-direction: row;
      gap: 5px;
      position: absolute;
      padding-right: 30px;
      padding-bottom: 18px;
      right: 2.5%;
      bottom: 0;
      align-items: flex-end;
      justify-content: end;
      min-width: 1200px;
      z-index:1;
    "
  >
    <span class="span-text-dark" style="font-size: 16px;">powered by</span>
    <img src="/assets/logos/bertlabs-logo.png" height="25px" width="100px" />
  </div>