<!-- <div style="position: relative">
  <div
    class="card"
    routerLink="../{{ item.route }}"
    style="position: absolute"
  >
    <img src={{item.image}} alt="" style="padding: 20px;"/>
    <div
      class="span-text-dark"
      style="
        color: var(--white);
        font-size: 16px;
        background-color: var(--blue-one);
        border: 1px solid var(--blue-one);
      "
    >
      {{ item.title }}
    </div>
  </div>

  <div [ngClass]="{'card-disabled': disabled}"
  ></div>
</div> -->


<div style="position: relative">
  <div *ngIf="item" class="card" [routerLink]="'../' + item.route" style="position: absolute">
    <img [src]="item.image" alt="" style="padding: 20px;"/>
    <div class="span-text-dark" style="color: var(--white); font-size: 16px; background-color: var(--blue-one); border: 1px solid var(--blue-one);">
      {{ item.title }}
    </div>
  </div>

  <div [ngClass]="{'card-disabled': disabled}"></div>
</div>
