
<div style="position: relative; height: 100%; width: 100%">
    <Water-mark></Water-mark>
    <div class="main_container">

      <mat-progress-bar mode="indeterminate" [ngClass]="{ 'show-progress': progress, 'hide-progress': !progress }"></mat-progress-bar>
      <div class="content-container">
        <div class="upcontainer">
          <!-- FAHU Map  -->
          <div class="map-container">
            <button class="button-unit-A" routerLink="../electrolysis-unit-A">
              </button>
              <button class="button-unit-B" routerLink="../electrolysis-unit-B">
            </button>
            <img class="fmap" src="../../../assets/atul-assets/Atul Asset/ELECTROLYSIS UNIT AND RECTIFIER.svg" alt="Electrolysis" />

            <!-- Components -->
            <!-- <div class="component fan" style="height: 14.5%; top: 66.9%; left: 50.65%;">
              <Fan [state]="state" ></Fan>
            </div> -->
            <!-- Lean Brine FLow  -->
            <div class="input-groupR RHc component" style="display: flex; justify-content: center; align-items: center; top: 30.3%; left: 9.1%; width: 7%; height: 3%; padding-left: 0.5%; background-color: aquamarine;">
              <input style=" background-color:aquamarine; color: #D90606; font-weight:600" [ngModel]="Flow_of_lean_brine_total_in | number: '1.2-2'" (ngModelChange)="Flow_of_lean_brine_total_in = $event" />
              <span style="font-size: 80%; color: #D90606; font-weight:600" >Kg/hr</span>
            </div>
            <!--  Total Caustic PRoduction -->
            <div class="input-groupR teampc component" style="display: flex; justify-content: center; align-items: center; top: 17.3%; left: 82%; width: 7.5%; height: 3%; padding-left: 0.5%; background-color: aquamarine;">
              <input style=" background-color:aquamarine; color: #D90606; font-weight:600" [ngModel]="Flow_rate_of_caustic_outlet_total_in | number: '1.2-2'" (ngModelChange)="Flow_rate_of_caustic_outlet_total_in = $event" />
              <span style="font-size: 80%; color: #D90606; font-weight:600" >kg/hr</span>
            </div>


              <!-- Hydrogen Gas Flow  -->
              <div class="input-groupR RHc component" style="display: flex; justify-content: center; align-items: center; top: 37.4%; left: 74.4%; width: 5.8%; height: 3%; padding-left: 0.5%; background-color: aquamarine;">
                <input style=" background-color:aquamarine; color: #D90606; font-weight:600" [ngModel]="H2_outlet_flow_based_on_NaCl_flow_total_in | number: '1.2-2'" (ngModelChange)="H2_outlet_flow_based_on_NaCl_flow_total_in = $event" />
                <span style="font-size: 80%; color: #D90606; font-weight:600" >Kg/hr</span>
              </div>
            <!-- Hydrogen Gas Temp -->
            <div class="input-groupR RHc component" style="display: flex; justify-content: center; align-items: center; top: 40%; left: 74.4%; width: 5.8%; height: 3%; padding-left: 0.5%; background-color: aquamarine;">
              <input style=" background-color:aquamarine; color: #D90606; font-weight:600" [ngModel]="ELE_cathode_header_total_in | number: '1.2-2'" (ngModelChange)="ELE_cathode_header_total_in = $event" />
              <span style="font-size: 80%; color: #D90606; font-weight:600" >°C</span>
            </div>

              <!-- Chlorine Gas Flow  -->
              <div class="input-groupR fanspeedc2 component" style="display: flex; justify-content: center; align-items: center; top: 36.8%; left: 89.2%; width: 5.8%; height: 3%; padding-left: 0.5%; background-color: aquamarine;">
                <input style=" background-color:aquamarine; color: #D90606; font-weight:600" [ngModel]="Cl2_outlet_flow_based_on_NaCl_flow_total_in | number: '1.2-2'" (ngModelChange)="Cl2_outlet_flow_based_on_NaCl_flow_total_in = $event" />
                <span style="font-size: 80%; color: #D90606; font-weight:600" >Kg/hr</span>
              </div>
               <!-- Chlorine Gas Temp  -->
               <div class="input-groupR fanspeedc2 component" style="display: flex; justify-content: center; align-items: center; top: 39.5%; left: 89.2%; width: 5.8%; height: 3%; padding-left: 0.5%; background-color: aquamarine;">
                <input style=" background-color:aquamarine; color: #D90606; font-weight:600" [ngModel]="ELE_anode_header_total_in | number: '1.2-2'" (ngModelChange)="ELE_anode_header_total_in = $event" />
                <span style="font-size: 80%; color: #D90606; font-weight:600" >°C</span>
              </div>


                <!-- Ultra Pure Brine flow  -->
              <div class="input-groupR fanspeedc2 component" style="display: flex; justify-content: center; align-items: center; top: 55.8%; left: 9%; width: 7%; height: 3%; padding-left: 0.5%; background-color: aquamarine;">
                <input style=" background-color:aquamarine; color: #D90606; font-weight:600" [ngModel]="Flow_of_brine_total_in | number: '1.2-2'" (ngModelChange)="Flow_of_brine_total_in = $event" />
                <span style="font-size: 80%; color: #D90606; font-weight:600" >Kg/hr</span>
              </div>
               <!--  Ultra Pure Brine temp -->
               <div class="input-groupR fanspeedc2 component" style="display: flex; justify-content: center; align-items: center; top: 58.5%; left: 9%; width: 7%; height: 3%; padding-left: 0.5%; background-color: aquamarine;">
                <input style=" background-color:aquamarine; color: #D90606; font-weight:600" [ngModel]="Pure_brine_to_cell_plant_total_in | number: '1.2-2'" (ngModelChange)="Flow_of_brine_total_in = $event" />
                <span style="font-size: 80%; color: #D90606; font-weight:600" >°C</span>
              </div>
                <!--  Ultra Pure Brine conc.  -->
                <div class="input-groupR fanspeedc2 component" style="display: flex; justify-content: center; align-items: center; top: 61%; left: 9%; width: 7%; height: 3%; padding-left: 0.5%; background-color: aquamarine;">
                  <input style=" background-color:aquamarine; color: #D90606; font-weight:600" [ngModel]="Concentration_of_saturated_brine_total_in | number: '1.2-2'" (ngModelChange)="Flow_of_brine_total_in = $event" />
                  <span style="font-size: 80%; color: #D90606; font-weight:600" >%</span>
                </div>

                 <!-- DM Water Flow -->
                 <div class="input-groupR fanspeedc2 component" style="display: flex; justify-content: center; align-items: center; top: 93.5%; left: 63.5%; width: 7%; height: 3%; padding-left: 0.5%; background-color: aquamarine;">
                  <input style=" background-color:aquamarine; color: #D90606; font-weight:600" [ngModel]="Total_dm_water_flow_to_electrolyser_total_in | number: '1.2-2'" (ngModelChange)="Total_dm_water_flow_to_electrolyser_total_in = $event" />
                  <span style="font-size: 80%; color: #D90606; font-weight:600" >Kg/hr</span>
                </div>
                  <!-- DM Water Temp  -->
                  <div class="input-groupR fanpowc component" style="display: flex; justify-content: center; align-items: center; top: 96.4%; left: 63.5%; width: 7%; height: 3%; padding-left: 0.5%; background-color: aquamarine;">
                    <input style=" background-color:aquamarine; color: #D90606; font-weight:600" [ngModel]="Temp_of_dm_feed_water_total_in | number: '1.2-2'" (ngModelChange)="Temp_of_dm_feed_water_B_in = $event" />
                    <span style="font-size: 80%; color: #D90606; font-weight:600" >°C</span>
                  </div>
                  <!-- DIluted Caustic Flow -->
                  <div class="input-groupR fanspeedc2 component" style="display: flex; justify-content: center; align-items: center; top: 59.8%; left: 81.5%; width: 7%; height: 3%; padding-left: 0.5%; background-color: aquamarine;">
                    <input style=" background-color:aquamarine; color: #D90606; font-weight:600" [ngModel]="Elect_caustic_feed_total_in | number: '1.2-2'" (ngModelChange)="Elect_caustic_feed_total_in = $event" />
                    <span style="font-size: 80%; color: #D90606; font-weight:600" >Kg/hr</span>
                  </div>
                    <!-- DIluted Caustic Temp  -->
                  <div class="input-groupR fanpowc component" style="display: flex; justify-content: center; align-items: center; top: 62.8%; left: 81.5%; width: 7%; height: 3%; padding-left: 0.5%; background-color: aquamarine;">
                    <input style=" background-color:aquamarine; color: #D90606; font-weight:600" [ngModel]="Temp_of_caustic_feed_to_catholyte_total_in | number: '1.2-2'" (ngModelChange)="Temp_of_caustic_feed_to_catholyte_total_in = $event" />
                    <span style="font-size: 80%; color: #D90606; font-weight:600" >°C</span>
                  </div>

                  <!-- Power  -->
                  <div class="input-groupR fanpowc component" style="display: flex; justify-content: center; align-items: center; top: 90%; left: 45%; width: 7%; height: 3%; padding-left: 0.5%; background-color: aquamarine;">
                    <input style=" background-color:aquamarine; color: #D90606; font-weight:600" [ngModel]="Power_total_in| number: '1.2-2'" (ngModelChange)="Power_total_in = $event" />
                    <span style="font-size: 80%; color: #D90606; font-weight:600" >kW</span>
                  </div>
                  <!-- Power  -->
                  <!-- <div class="input-groupR fanpowc component" style="display: flex; justify-content: center; align-items: center; top: 93%; left: 45%; width: 7%; height: 3%; padding-left: 0.5%; background-color: aquamarine;">
                    <input style=" background-color:aquamarine; color: #00A578; font-weight:600" [ngModel]="Power_total_out | number: '1.2-2'" (ngModelChange)="Power_total_in = $event" />
                    <span style="font-size: 80%; color: #00A578; font-weight:600" >kW</span>
                  </div> -->
                  <div class="input-groupR fanpowc component" style="display: flex; justify-content: center; align-items: center; top: 93%; left: 45%; width: 7%; height: 3%; padding-left: 0.5%; background-color: aquamarine;">
                    <input style=" background-color:aquamarine; color: #00A578; font-weight:600" [ngModel]="(Power_total_in)-(power_saving*Power_total_in)/300 | number: '1.2-2'" (ngModelChange)="Power_total_in = $event" />
                    <span style="font-size: 80%; color: #00A578; font-weight:600" >kW</span>
                  </div>




            <div class="form component">
              <span class="span-text-dark">Key Performance Indicator</span>

              <!-- <div style="height: 40px;"></div> -->
              <div  class="hvac-text" style="background-color: white;">
              <span class="span-text-dark" style="display:flex; font-size: 110%; text-align:left; font-weight: 500; margin-left:0%; align-items:center;">Caustic Production Improvement</span>
              <div style="width:25%; ">
                  <input style="  width:50px; font-size: 16px; text-align:center; border-radius:8px; border: 2px solid #C4C4C4  "
                  [ngModel]="caustic_product/2 | number : '1.2-2'"
                  (ngModelChange)="caustic_product = $event" />
              </div>
              <span style="margin-right:10%; font-size: 100%;">%</span>
              </div>

              <div  class="hvac-text" style="background-color: white;">
                <span class="span-text-dark" style="display:flex; font-size: 110%; text-align:left; font-weight: 500; align-items:center;">Power Saving</span>
                <div style=" margin-left:22%; ">
                    <input style="  width:50px; font-size: 16px; text-align:center; border-radius:8px; border: 2px solid #C4C4C4  "
                    [ngModel]="power_saving/3 | number : '1.2-2'"
                    (ngModelChange)="power_saving = $event" />
                </div>
                <span style="margin-right:10%; font-size: 100%;">%</span>
                </div>
          </div>

          </div>
        </div>
      </div>
    </div>
  </div>
