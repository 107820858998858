import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-electrolysis-unit-a',
  templateUrl: './electrolysis-unit-a.component.html',
  styleUrls: ['./electrolysis-unit-a.component.css']
})



export class ElectrolysisUnitAComponent {
  type!: number;
  predict_response: any = {};
  predict_input: any = {};
  optimise_input: any = {};
  optimise_response: any = {};
  progress: boolean = false;
  disableOptimise: boolean = true;
  electrolyser_out_A: any;
  electrolyser_out_B: any;
  electrolyser_input_A: any;
  // input form fields
  Elect_caustic_feed_A: number = 0;               //input-3
  Rect_voltage_A: number = 0;                     //input-8
  Rect_current_A: number = 0;                    //input-9
  Pure_brine_to_cell_plant_A: number = 0;         //input-5
  Cl2_pressure_A: number = 0;
  H2_pressure_A: number = 0;
  ELE_anode_header_A: number = 0;
  ELE_cathode_header_A: number = 0;
  Temp_of_dm_feed_water_A: number = 0;            //input-6
  Temp_of_caustic_outlet_A: number = 0;
  Temp_of_caustic_feed_to_catholyte_A: number = 0;   //input-7
  Concentration_of_saturated_brine_A: number = 0;   //input-4
  Concentration_of_caustic_outlet_A: number = 0;
  Total_dm_water_flow_to_electrolyser_A: number = 0;    //input-2
  Flow_rate_of_caustic_outlet_A: number = 0;
  Flow_of_brine_A: number = 0;                       //input-1
  H2_outlet_flow_based_on_NaCl_flow_A: number = 0;
  Cl2_outlet_flow_based_on_NaCl_flow_A: number = 0;
  Flow_of_lean_brine_A: number = 0;
  model_no: number = 1;

  // output form fields
  // Ultra Pure Brine
  Flow_of_brine_A_in: number = 0;
  Pure_brine_to_cell_plant_A_in: number = 0;
  Concentration_of_saturated_brine_A_in: number = 0;
  // Lean Brine
  Flow_of_lean_brine_A_in: number = 0;
  // Hydrogen Gas
  H2_pressure_A_in: number = 0;
  H2_outlet_flow_based_on_NaCl_flow_A_in: number = 0;
  // Chlorine Gas
  Cl2_pressure_A_in: number = 0;
  Cl2_outlet_flow_based_on_NaCl_flow_A_in: number = 0;
  // Caustic Production
  Flow_rate_of_caustic_outlet_A_in: number = 0;
  Temp_of_caustic_outlet_A_in: number = 0;
  Concentration_of_caustic_outlet_A_in: number = 0;
  // DIluted Caustic
  Elect_caustic_feed_A_in: number = 0;
  Temp_of_caustic_feed_to_catholyte_A_in: number = 0;
  // DM Water
  Total_dm_water_flow_to_electrolyser_A_in: number = 0;
  Temp_of_dm_feed_water_A_in: number = 0;
  Power_A_in: number = 0;
  Rect_voltage_A_in: number = 0;
  Rect_current_A_in: number = 0;


  ELE_anode_header_A_in: number = 0;
  ELE_cathode_header_A_in: number = 0;

  Flow_rate_of_caustic_outlet_A_out: number=0;
  Elect_caustic_feed_A_out: number = 0;
  Flow_of_brine_A_out: number = 0;
  Power_A_out: number = 0;
  Rect_current_A_out: number = 0;
  Rect_voltage_A_out: number = 0;
  Total_dm_water_flow_to_electrolyser_A_out: number = 0;
  power_saving_A:number=0;
  caustic_production:number=0;

  predictionResult: any = {};
  interfaceResult: any = {};
  jsonData: any = {};




  hasdata: boolean = true;

  // KPI DATA
  KPI: number = 0;

  constructor(
    private activeroute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private snackBar: MatSnackBar
  ) {


    if(localStorage.getItem('predictionResultA') && localStorage.getItem('interface_inputA') ){
      this.interfaceResult=localStorage.getItem('interface_inputA');
      this.interfaceResult=JSON.parse(this.interfaceResult);
      this.predictionResult=localStorage.getItem('predictionResultA');
      this.predictionResult=JSON.parse(this.predictionResult);
      this.Rect_current_A_out = this.predictionResult['Rect_current_A'];
      this.Rect_voltage_A_out = this.predictionResult['Rect_voltage_A'];
      this.Power_A_out = ((this.Rect_current_A_out)*(this.Rect_voltage_A_out));
      this.Power_A_in=this.interfaceResult['Power_A'];
      // this.Power_A_out = ((this.Rect_current_A_out)*(this.Rect_voltage_A_out));

      this.power_saving_A=(((this.Power_A_in-this.Power_A_out)/this.Power_A_in)*100);

      this.Flow_rate_of_caustic_outlet_A_in = this.interfaceResult['Flow_rate_of_caustic_outlet_A'];
      this.Flow_rate_of_caustic_outlet_A_out = this.predictionResult['Flow_of_brine_A']*0.684;
      this.caustic_production=(-1)*(((this.Flow_rate_of_caustic_outlet_A_in-this.Flow_rate_of_caustic_outlet_A_out)/this.Flow_rate_of_caustic_outlet_A_in)*100);
      if(this.caustic_production<0) {
        this.caustic_production=(-1)*this.caustic_production;
      }
      if(this.power_saving_A<0) {
        this.power_saving_A=(-1)*this.power_saving_A;
      }
      console.log(this.Power_A_out,"heyyy");

    }else{
      this.caustic_production = 0;
      this.power_saving_A=0;
    }

    this.disableOptimise = !this.hasdata;

    console.log('hasdata', this.hasdata);
    console.log('disable', this.disableOptimise);
  }

  ngOnInit(): void {
    this.getPredictionResult();
    this.getInputResult();
    setInterval(() => {
      this.getPredictionResult();
      this.getInputResult();
    }, 240000); // 240000 milliseconds = 4 minutes
  }
  async getInputResult() {
    this.http.get<any>('https://bpsliveatchloralkali.bertlabs.com/get_result')
      .subscribe(
        (response: any) => {
          try {
            // Log the response to check its content
            console.log('Interface response:', response);

            // Check if the response is a string and parse it if necessary
            if (typeof response.result === 'string') {
              this.interfaceResult = JSON.parse(response.result);
            } else {
              this.interfaceResult = response.result;
            }
            this.Cl2_outlet_flow_based_on_NaCl_flow_A_in = this.interfaceResult['Cl2_outlet_flow_based_on_NaCl_flow_A'];
            this.Cl2_pressure_A_in = this.interfaceResult['Cl2_pressure_A'];
            this.Concentration_of_caustic_outlet_A_in = this.interfaceResult['Concentration_of_caustic_outlet_A'];
            this.Concentration_of_saturated_brine_A_in = this.interfaceResult['Concentration_of_saturated_brine_A'];
            this.ELE_anode_header_A_in = this.interfaceResult['ELE_anode_header_A'];
            this.ELE_cathode_header_A_in = this.interfaceResult['ELE_cathode_header_A'];
            this.Elect_caustic_feed_A_in = this.interfaceResult['Elect_caustic_feed_A'];
            this.Flow_of_brine_A_in = this.interfaceResult['Flow_of_brine_A'];
            this.Flow_of_lean_brine_A_in = this.interfaceResult['Flow_of_lean_brine_A'];
            this.Flow_rate_of_caustic_outlet_A_in = this.interfaceResult['Flow_rate_of_caustic_outlet_A'];
            this.H2_outlet_flow_based_on_NaCl_flow_A_in = this.interfaceResult['H2_outlet_flow_based_on_NaCl_flow_A'];
            this.H2_pressure_A_in = this.interfaceResult['H2_pressure_A'];
            this.Power_A_in = this.interfaceResult['Power_A'];
            this.Pure_brine_to_cell_plant_A_in = this.interfaceResult['Pure_brine_to_cell_plant_A'];
            this.Rect_current_A_in = this.interfaceResult['Rect_current_A'];
            this.Rect_voltage_A_in = this.interfaceResult['Rect_voltage_A'];
            this.Temp_of_caustic_feed_to_catholyte_A_in = this.interfaceResult['Temp_of_caustic_feed_to_catholyte_A'];
            this.Temp_of_caustic_outlet_A_in = this.interfaceResult['Temp_of_caustic_outlet_A'];
            this.Temp_of_dm_feed_water_A_in = this.interfaceResult['Temp_of_dm_feed_water_A'];
            this.Total_dm_water_flow_to_electrolyser_A_in = this.interfaceResult['Total_dm_water_flow_to_electrolyser_A'];
            console.log('Interface result:', this.interfaceResult);
            this.jsonData['interfaceResult'] = this.interfaceResult;
            console.log(this.jsonData);
            localStorage.setItem('interface_inputA', JSON.stringify(this.interfaceResult));
            console.log('interface_output', this.predict_response);
          } catch (error) {
            console.error('Error parsing Interface result:', error);
          }
        },
        (error) => {
          console.error('Error fetching Interface result:', error);
          if (error.error instanceof ErrorEvent) {
            // Client-side or network error
            console.error('Client-side error:', error.error.message);
          } else {
            // Backend returned an unsuccessful response code
            console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
          }
        }
      );
  }

  async getPredictionResult() {
    this.http.get<any>('https://bpsliveatchloralkali.bertlabs.com/get_prediction_result')
      .subscribe(
        (response: any) => {
          try {
            // Log the response to check its content
            console.log('Prediction response:', response);

            // Check if the response is a string and parse it if necessary
            if (typeof response.result === 'string') {
              this.predictionResult = JSON.parse(response.result);
            } else {
              this.predictionResult = response.result;
            }
            this.Flow_rate_of_caustic_outlet_A_out = this.predictionResult['Flow_of_brine_A']*0.684;
            this.Flow_of_brine_A_out = this.predictionResult['Flow_of_brine_A'];
            this.Rect_current_A_out = this.predictionResult['Rect_current_A'];
            this.Rect_voltage_A_out = this.predictionResult['Rect_voltage_A'];
            this.Power_A_out = (((this.Rect_current_A_out)*(this.Rect_voltage_A_out))/1000);
            this.Total_dm_water_flow_to_electrolyser_A_out = this.predictionResult['Total_dm_water_flow_to_electrolyser_A'];

            this.KPI = (((this.Power_A_in - this.Power_A_out)/this.Power_A_in)*100);

            console.log('Prediction result:', this.predictionResult);
            this.jsonData['predictionResult'] = this.predictionResult;
            console.log(this.jsonData);
            localStorage.setItem('predictionResultA', JSON.stringify(this.predictionResult));
            console.log('predict_output', this.predict_response);
          } catch (error) {
            console.error('Error parsing prediction result:', error);
          }
        },
        (error) => {
          console.error('Error fetching prediction result:', error);
          if (error.error instanceof ErrorEvent) {
            // Client-side or network error
            console.error('Client-side error:', error.error.message);
          } else {
            // Backend returned an unsuccessful response code
            console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
          }
        }
      );
  }

  // // Previous or baseline values
  // previousCausticProduction: number = 1000; // Example baseline value in Kg/hr
  // baselinePower: number = 500; // Example baseline power in kW

  // // Current values
  // currentCausticProduction: number = 0;
  // currentPower: number = 0;

  // KPI Calculations
  // get kpiCausticProduction(): number {
  //   if (this.Flow_rate_of_caustic_outlet_A === 0) {
  //     return 0; // Avoid division by zero
  //   }
  //   return ((this.Flow_rate_of_caustic_outlet_A_out - this.Flow_rate_of_caustic_outlet_A) / this.Flow_rate_of_caustic_outlet_A) * 100;
  // }

  // get kpiPowerSaving(): number {
  //   if (this.Power_A_in === 0) {
  //     return 0; // Avoid division by zero
  //   }
  //   return ((this.Power_A_in - this.Power_A_out) / this.Power_A_in) * 100;
  // }

  // // Methods to update current values based on user input
  // updateCurrentCausticProduction(value: number): void {
  //   this.Flow_rate_of_caustic_outlet_A = value;
  // }

  // updateCurrentPower(value: number): void {
  //   this.Power_A_in = value;
  // }



  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };




  // Elect_caustic_feed_A: number = 0;               //input-5
  // Rect_voltage_A: number = 0;                     //input-8
  // Rect_current_A: number = 0;                    //input-9
  // Pure_brine_to_cell_plant_A: number = 0;         //input-2
  // Temp_of_dm_feed_water_A: number = 0;            //input-4
  // Temp_of_caustic_feed_to_catholyte_A: number = 0;   //input-6
  // Concentration_of_saturated_brine_A: number = 0;   //input-7
  // Total_dm_water_flow_to_electrolyser_A: number = 0;    //input-3
  // Flow_of_brine_A: number = 0;                       //input-1
  // Validation

  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: 'start',
      duration: 5000,
    });
  }




}
