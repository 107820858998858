<div class="scaffold">
    <Nav-bar  ></Nav-bar>
  
    <div style="height: 100%; display: flex; flex-direction: row;overflow-x: hidden;overflow-y:hidden;">
      <div style="height: 100%;">
        <Main-side-bar ></Main-side-bar>
      </div>
    
      <div style="width: 100%;overflow-y: scroll; overflow-x: scroll;">
        <router-outlet></router-outlet>
        <!-- <div class="watermark" style="position: absolute;bottom: 20px; right: -300px;z-index: 9;">
          <Water-mark></Water-mark>
        </div> -->
      </div>
    </div>
  </div>
  