import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'Main-side-bar',
  templateUrl: './main-side-bar.component.html',
  styleUrls: ['./main-side-bar.component.css'],
})
export class MainSideBarComponent implements OnInit {
  @Output() titleEmmiter: EventEmitter<string> = new EventEmitter<string>();

  block: string = '';

  routing_paths: string[] = [];
  showNavbar: boolean = true;
  expanded: boolean = false;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private location: Location, private http: HttpClient) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.checkNavbarVisibility(activatedRoute);
      }
    });
  }

  private checkNavbarVisibility(route: ActivatedRoute): void {
    if (route.firstChild) {
      route.firstChild.url.subscribe((segments) => {
        console.log(segments);
        this.block = segments[0].path;

        let selectedItem = this.items.find(item => item.route === this.block);

        if (selectedItem) {
          this.toggleExpanded(selectedItem);
        }

        for (let item of this.items) {
          if (item.route != this.block && this.block.includes('block')) {
            item.expanded = false;
          }
        }

        if (segments.length > 0 && segments[0].path === 'home') {
          this.showNavbar = false;
        } else {
          this.showNavbar = false;
        }
      });
      this.checkNavbarVisibility(route.firstChild);
    }
  }





  isToggled = false;

  selectedItem: any;
  items: any[] = [

    
    {
      title: 'Electrolysis Unit 1',
      route: '/dashboard/electrolysis-unit-one',
      disabled: false
    },
    {
      title: 'Electrolysis Unit 2',
      route: '/dashboard/electrolysis-unit-two',
      disabled: false
    },
    {
      title: 'Electrolysis Unit 3',
      route: '/dashboard/electrolysis-unit-three',
      disabled: false
    },
    {
      title: 'Electrolysis Unit 4',
      route: '/dashboard/electrolysis-unit-four',
      disabled: false
    },
    {
      title: 'Electrolysis Unit 5',
      route: '/dashboard/electrolysis-unit-five',
      disabled: false
    },
    {
      title: 'Electrolysis Unit 6',
      route: '/dashboard/electrolysis-unit-six',
      disabled: false
    },

  ];

  toggleExpanded(item: any) {
    item.expanded = !item.expanded;
  }

  isSelectedDescription(item: any, description: any): boolean {
    return this.selectedItem === item && item.expanded;
  }

  toggleButton(event: any): void {
    this.isToggled = event.source.checked;
  }

  goback() {
    this.location.back();
  }

  handleItemClick(subComponent: any): void {
    console.log('Clicked on:', subComponent);

    if (this.routing_paths.includes(subComponent.route)) {
      // Navigate to the route
      this.router.navigate([subComponent.route]);
    }
  }

  updateDisabledStatus() {
    for (const item of this.items) {
      for (const subComponent of item.subComponents) {
        subComponent.disabled = false;
      }
    }
  }


  async ngOnInit() {
    this.updateDisabledStatus();
  }
}
