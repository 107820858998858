import { Component } from '@angular/core';

@Component({
  selector: 'app-electrolysis-unit-and-rectifier',
  templateUrl: './electrolysis-unit-and-rectifier.component.html',
  styleUrls: ['./electrolysis-unit-and-rectifier.component.css']
})
export class ElectrolysisUnitAndRectifierComponent {
    type!: number;
    electrolyser_out_A: any;
    electrolyser_out_B: any;
    Flow_of_brine_B_in: number = 0;
    Flow_of_lean_brine_B_in: number = 0;
    Power_B_in: number = 0;
    H2_outlet_flow_based_on_NaCl_flow_B_in: number = 0;
    Cl2_outlet_flow_based_on_NaCl_flow_B_in: number = 0;
    Flow_rate_of_caustic_outlet_B_in: number = 0;
    Elect_caustic_feed_B_in: number = 0;
    Total_dm_water_flow_to_electrolyser_B_in: number = 0;
    Flow_of_brine_A_in: number = 0;
    Flow_of_lean_brine_A_in: number = 0;
    Power_A_in: number = 0;
    H2_outlet_flow_based_on_NaCl_flow_A_in: number = 0;
    Cl2_outlet_flow_based_on_NaCl_flow_A_in: number = 0;
    Flow_rate_of_caustic_outlet_A_in: number = 0;
    Elect_caustic_feed_A_in: number = 0;
    Total_dm_water_flow_to_electrolyser_A_in: number = 0;
    Flow_of_brine_total_in: number = 0;
    Flow_of_lean_brine_total_in: number = 0;
    Power_total_in: number = 0;
    H2_outlet_flow_based_on_NaCl_flow_total_in: number = 0;
    Cl2_outlet_flow_based_on_NaCl_flow_total_in: number = 0;
    Flow_rate_of_caustic_outlet_total_in: number = 0;
    Elect_caustic_feed_total_in: number = 0;
    Total_dm_water_flow_to_electrolyser_total_in: number = 0;
    
    KPI: number = 0;
    ELE_cathode_header_total_in: number=0;
    ELE_cathode_header_A_in: number=0;
    ELE_cathode_header_B_in: number=0;
    
    ELE_anode_header_A_in: number=0;
    ELE_anode_header_B_in: number=0;
    ELE_anode_header_total_in: number=0;
    Temp_of_dm_feed_water_B_in: number=0;
    Pure_brine_to_cell_plant_A_in: number=0;
    Pure_brine_to_cell_plant_B_in: number=0;
    Pure_brine_to_cell_plant_total_in: number=0;
    Concentration_of_saturated_brine_A_in: number=0;
    Concentration_of_saturated_brine_B_in: number=0;
    Concentration_of_saturated_brine_total_in: number=0;
    progress: any;

interface_inputA: any = {};
interface_inputB: any = {};
predictionResultA: any = {};
predictionResultB: any = {};
Temp_of_dm_feed_water_total_in: number=0;
Temp_of_caustic_feed_to_catholyte_total_in: number=0;
Power_total_out: number=0;
  Temp_of_dm_feed_water_A_in: number=0;
  Temp_of_caustic_feed_to_catholyte_B_in: number=0;
  Temp_of_caustic_feed_to_catholyte_A_in: number=0;
  Power_A_out: number=0;
  Power_B_out: number=0;
  Rect_current_A_out: number=0;
  Rect_voltage_A_out: number=0;
  Rect_current_B_out: number=0;
  Rect_voltage_B_out: number=0;
  power_saving:number=0;
  Flow_rate_of_caustic_outlet_A_out:number=0;
  Flow_rate_of_caustic_outlet_B_out:number=0;
  caustic_product:number=0;

    constructor(){
      if (localStorage.getItem('Abs Fan Power kWh') != null) {
        this.KPI =
          ((1.81 - Number(localStorage.getItem('Abs Fan Power kWh'))) * 100) /
          1.81;
      } else {
        this.KPI = 0;
      }

      if(localStorage.getItem('interface_inputB') && localStorage.getItem('interface_inputA') ){
        this.interface_inputB=localStorage.getItem('interface_inputB');
        this.interface_inputB=JSON.parse(this.interface_inputB);
        this.interface_inputA=localStorage.getItem('interface_inputA');
        this.interface_inputA=JSON.parse(this.interface_inputA);
        console.log("interface_A", this.interface_inputA );
        console.log("interface_B", this.interface_inputB );
        this.Flow_of_lean_brine_A_in = this.interface_inputA['Flow_of_lean_brine_A'];
        this.Flow_of_lean_brine_B_in = this.interface_inputB['Flow_of_lean_brine_B'];
        this.Flow_of_lean_brine_total_in = this.Flow_of_lean_brine_A_in + this.Flow_of_lean_brine_B_in;
        console.log("flow of lean brine",this.Flow_of_lean_brine_total_in);
        this.Flow_rate_of_caustic_outlet_A_in = this.interface_inputA['Flow_rate_of_caustic_outlet_A'];
        this.Flow_rate_of_caustic_outlet_B_in = this.interface_inputB['Flow_rate_of_caustic_outlet_B'];
        this.Flow_rate_of_caustic_outlet_total_in = this.Flow_rate_of_caustic_outlet_A_in + this. Flow_rate_of_caustic_outlet_B_in;
        this.H2_outlet_flow_based_on_NaCl_flow_A_in = this.interface_inputA['H2_outlet_flow_based_on_NaCl_flow_A'];
        this.H2_outlet_flow_based_on_NaCl_flow_B_in = this.interface_inputB['H2_outlet_flow_based_on_NaCl_flow_B'];
        this.H2_outlet_flow_based_on_NaCl_flow_total_in = this.H2_outlet_flow_based_on_NaCl_flow_A_in + this.H2_outlet_flow_based_on_NaCl_flow_B_in; 
        this.ELE_cathode_header_A_in = this.interface_inputA['ELE_cathode_header_A'];
        this.ELE_cathode_header_B_in = this.interface_inputB['ELE_cathode_header_B'];
        this.ELE_cathode_header_total_in = (this.ELE_cathode_header_A_in + this.ELE_cathode_header_B_in)/2;
        this.Cl2_outlet_flow_based_on_NaCl_flow_A_in = this.interface_inputA['Cl2_outlet_flow_based_on_NaCl_flow_A'];
        this.Cl2_outlet_flow_based_on_NaCl_flow_B_in = this.interface_inputB['Cl2_outlet_flow_based_on_NaCl_flow_B'];
        this.Cl2_outlet_flow_based_on_NaCl_flow_total_in = this.Cl2_outlet_flow_based_on_NaCl_flow_A_in + this.Cl2_outlet_flow_based_on_NaCl_flow_B_in;
        this.ELE_anode_header_A_in = this.interface_inputA['ELE_anode_header_A'];
        this.ELE_anode_header_B_in = this.interface_inputB['ELE_anode_header_B'];
        this.ELE_anode_header_total_in = (this.ELE_anode_header_A_in + this.ELE_anode_header_B_in)/2;
        this.Flow_of_brine_A_in = this.interface_inputA['Flow_of_brine_A'];
        this.Flow_of_brine_B_in = this.interface_inputB['Flow_of_brine_B'];
        this.Flow_of_brine_total_in = this.Flow_of_brine_A_in + this.Flow_of_brine_B_in;
        this.Pure_brine_to_cell_plant_A_in = this.interface_inputA['Pure_brine_to_cell_plant_A'];
        this.Pure_brine_to_cell_plant_B_in = this.interface_inputB['Pure_brine_to_cell_plant_B'];
        this.Pure_brine_to_cell_plant_total_in = (this.Pure_brine_to_cell_plant_A_in + this.Pure_brine_to_cell_plant_B_in)/2;
        this.Concentration_of_saturated_brine_A_in = this.interface_inputA['Concentration_of_saturated_brine_A'];
        this.Concentration_of_saturated_brine_B_in = this.interface_inputB['Concentration_of_saturated_brine_B'];
        this.Concentration_of_saturated_brine_total_in = this.Concentration_of_saturated_brine_A_in + this.Concentration_of_saturated_brine_B_in;
        this.Total_dm_water_flow_to_electrolyser_A_in = this.interface_inputA['Total_dm_water_flow_to_electrolyser_A'];
        this.Total_dm_water_flow_to_electrolyser_B_in = this.interface_inputB['Total_dm_water_flow_to_electrolyser_B'];
        this.Total_dm_water_flow_to_electrolyser_total_in = this.Total_dm_water_flow_to_electrolyser_A_in + this.Total_dm_water_flow_to_electrolyser_B_in;
        this.Temp_of_dm_feed_water_A_in = this.interface_inputA['Temp_of_dm_feed_water_A'];
        this.Temp_of_dm_feed_water_B_in = this.interface_inputB['Temp_of_dm_feed_water_B'];
        this.Temp_of_dm_feed_water_total_in = (this.Temp_of_dm_feed_water_B_in + this.Temp_of_dm_feed_water_A_in)/2;
        this.Elect_caustic_feed_A_in = this.interface_inputA['Elect_caustic_feed_A'];
        this.Elect_caustic_feed_B_in = this.interface_inputB['Elect_caustic_feed_B'];
        this.Elect_caustic_feed_total_in = this.Elect_caustic_feed_A_in + this.Elect_caustic_feed_B_in;
        this.Temp_of_caustic_feed_to_catholyte_A_in = this.interface_inputA['Temp_of_caustic_feed_to_catholyte_A'];
        this.Temp_of_caustic_feed_to_catholyte_B_in = this.interface_inputB['Temp_of_caustic_feed_to_catholyte_B'];
        this.Temp_of_caustic_feed_to_catholyte_total_in = (this.Temp_of_caustic_feed_to_catholyte_A_in + this.Temp_of_caustic_feed_to_catholyte_B_in)/2;
        this.Power_A_in = ((this.interface_inputA['Power_A'])/1000);
        this.Power_B_in = ((this.interface_inputB['Power_B'])/1000);
        this.Power_total_in = this.Power_A_in+ this.Power_B_in;
        
      
      }else{
        this.Flow_of_lean_brine_total_in = 0;
        this.Flow_rate_of_caustic_outlet_total_in = 0;
        this.H2_outlet_flow_based_on_NaCl_flow_total_in = 0;
        this.ELE_cathode_header_total_in = 0;
        this.Cl2_outlet_flow_based_on_NaCl_flow_total_in = 0;
        this.ELE_anode_header_total_in = 0;
        this.Flow_of_brine_total_in = 0;
        this.Pure_brine_to_cell_plant_total_in = 0;
        this.Concentration_of_saturated_brine_total_in = 0;
        this.Total_dm_water_flow_to_electrolyser_total_in = 0;
        this.Temp_of_dm_feed_water_total_in = 0;
        this.Elect_caustic_feed_total_in = 0;
        this.Temp_of_caustic_feed_to_catholyte_total_in = 0;
        this.Power_total_in = 0;
      }

      if(localStorage.getItem('predictionResultA') && localStorage.getItem('predictionResultB') ){
        this.predictionResultB=localStorage.getItem('predictionResultB');
        this.predictionResultB=JSON.parse(this.predictionResultB);
        this.predictionResultA=localStorage.getItem('predictionResultA');
        this.predictionResultA=JSON.parse(this.predictionResultA);
        this.Rect_current_A_out = this.predictionResultB['Rect_current_A'];
        this.Rect_voltage_A_out = this.predictionResultB['Rect_voltage_A'];
        this.Power_A_out = (((this.Rect_current_A_out)*(this.Rect_voltage_A_out))/1000);
        this.Rect_current_B_out = this.predictionResultB['Rect_current_B'];
        this.Rect_voltage_B_out = this.predictionResultB['Rect_voltage_B'];
        this.Power_B_out = (((this.Rect_current_B_out)*(this.Rect_voltage_B_out))/1000);
        this.Power_total_out = this.Power_A_out + this.Power_B_out;
        console.log("power_out", this.Power_total_out);
      }else{
        this.Power_total_out = 0;
      }
      // power_saving
      if(localStorage.getItem('predictionResultA') && localStorage.getItem('interface_inputA') ){
        this.interface_inputA=localStorage.getItem('interface_inputA');
        this.interface_inputA=JSON.parse(this.interface_inputA);
        this.predictionResultA=localStorage.getItem('predictionResultA');
        this.predictionResultA=JSON.parse(this.predictionResultA);
        this.Rect_current_A_out = this.predictionResultB['Rect_current_A'];
        this.Rect_voltage_A_out = this.predictionResultB['Rect_voltage_A'];
        this.Power_A_out = ((this.Rect_current_A_out)*(this.Rect_voltage_A_out));
        this.Rect_current_B_out = this.predictionResultB['Rect_current_B'];
        this.Rect_voltage_B_out = this.predictionResultB['Rect_voltage_B'];
        this.Power_B_out = ((this.Rect_current_B_out)*(this.Rect_voltage_B_out));
        this.Power_total_out = this.Power_A_out + this.Power_B_out;
        this.Power_A_in = this.interface_inputA['Power_A'];
        this.Power_B_in = this.interface_inputB['Power_B'];
        this.Power_total_in = this.Power_A_in+ this.Power_B_in;
        console.log("power_out", this.Power_total_out);
        this.power_saving=(((this.Power_total_in-this.Power_total_out)/this.Power_total_in)*100);

        this.Flow_rate_of_caustic_outlet_B_in = this.interface_inputB['Flow_rate_of_caustic_outlet_B'];
      this.Flow_rate_of_caustic_outlet_B_out = this.predictionResultB['Flow_of_brine_B']*0.684;
      this.Flow_rate_of_caustic_outlet_A_in = this.interface_inputA['Flow_rate_of_caustic_outlet_B'];
      this.Flow_rate_of_caustic_outlet_A_out = this.predictionResultA['Flow_of_brine_B']*0.684;
      
      this.caustic_product=((((this.Flow_rate_of_caustic_outlet_B_out+this.Flow_rate_of_caustic_outlet_A_out)-
      (this.Flow_rate_of_caustic_outlet_A_in+this.Flow_rate_of_caustic_outlet_B_in))/
      (this.Flow_rate_of_caustic_outlet_A_in+this.Flow_rate_of_caustic_outlet_B_in))*100);
        if(this.caustic_product<0) {
          this.caustic_product=(-1)*this.caustic_product;
        }
        if(this.power_saving<0) {
          this.power_saving=(-1)*this.power_saving;
        }


      }else{
        this.power_saving = 0;
        this.caustic_product=0;
      }





      


    }

}
