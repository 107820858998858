import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './pages/login/login.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { HomeComponent } from './pages/home/home.component';
import { MainSideBarComponent } from './components/main-side-bar/main-side-bar.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { LogoutDialogComponent } from './components/logout-dialog/logout-dialog.component';
import { CardComponent } from './components/card/card.component';
import { WaterMarkComponent } from './components/water-mark/water-mark.component';

// angular
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { HttpClientModule } from '@angular/common/http';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { ElectrolysisTemplateComponent } from './components/electrolysis-template/electrolysis-template.component';
import { ElectrolysisUnitAndRectifierComponent } from './pages/atul-electrolysis-unit-and-rectifier/electrolysis-unit-and-rectifier/electrolysis-unit-and-rectifier.component';
import { ElectrolysisUnitAComponent } from './pages/atul-electrolysis-unit-and-rectifier/electrolysis-unit-a/electrolysis-unit-a.component';
import { ElectrolysisUnitBComponent } from './pages/atul-electrolysis-unit-and-rectifier/electrolysis-unit-b/electrolysis-unit-b.component';
import { LoadingPageComponent } from './pages/loading-page/loading-page.component';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './guards/auth.guard';
import { AdminGuard } from './guards/admin.guard';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    DashboardComponent,
    HomeComponent,
    MainSideBarComponent,
    CardComponent,
    WaterMarkComponent,
    ElectrolysisTemplateComponent,
    ElectrolysisUnitAndRectifierComponent,
    ElectrolysisUnitAComponent,
    ElectrolysisUnitBComponent,
    LoadingPageComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    FormsModule,
    MatSidenavModule,
    MatListModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    MatInputModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatProgressBarModule,
    LogoutDialogComponent,
    NavBarComponent

  ],
  providers: [ 

    { 

      provide: MAT_DIALOG_DEFAULT_OPTIONS, 

      useValue: { hasBackdrop: true, direction: 'ltr' }, 

    }, 

    AuthService,AuthGuard,AdminGuard

  ], 
  bootstrap: [AppComponent]
})
export class AppModule { }
