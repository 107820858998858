import { Component, HostListener } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  @HostListener('document:keydown', ['$event'])
  disableSpecialKeys(event: KeyboardEvent): void {
    // Add keys you want to disable
    if (event.ctrlKey || event.altKey || event.key === 'F12' || event.key === 'F1' || event.key === 'F2') {
      event.preventDefault();
    }
  }
  disableRightClick(event: MouseEvent): void {
    event.preventDefault();
  }
  title = 'electrolysis';



  constructor(private router: Router, public authService: AuthService) {

    this.router.events.subscribe(event => {

      if (event instanceof NavigationStart) {

        const currentUrl = event.url;

        const isNotLoginPage = this.router.url !== '/login';



        if (currentUrl === '/loading-screen' && this.authService.isLoggedIn() && isNotLoginPage) {

          // If user navigates to loading page from a protected page and is logged in, logout and redirect to login 

          this.authService.logout();

          this.router.navigate(['/login']);

        }

        if (currentUrl === '/login' && this.authService.isLoggedIn()) {

          // If user navigates to login page and is logged in, logout 

          this.authService.logout();

        }



      }

    });

  }
}
