import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-electrolysis-template',
  templateUrl: './electrolysis-template.component.html',
  styleUrls: ['./electrolysis-template.component.css']
})
export class ElectrolysisTemplateComponent {
  constructor(private http: HttpClient) {
    this.http.get('assets/electrolysis.json', { responseType: 'text' })
    .subscribe(data => {
      const ahusData = JSON.parse(data);
      console.log(data)
      for (const key in ahusData) {
        if (ahusData.hasOwnProperty(key) && !localStorage.getItem(key) ) {
          localStorage.setItem(key, JSON.stringify(ahusData[key]));
        }
      }
    });
  }

  async predictData(){
    
  }
}
