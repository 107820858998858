import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { HomeComponent } from './pages/home/home.component';
import { ElectrolysisUnitAndRectifierComponent } from './pages/atul-electrolysis-unit-and-rectifier/electrolysis-unit-and-rectifier/electrolysis-unit-and-rectifier.component';
import { ElectrolysisUnitAComponent } from './pages/atul-electrolysis-unit-and-rectifier/electrolysis-unit-a/electrolysis-unit-a.component';
import { ElectrolysisUnitBComponent } from './pages/atul-electrolysis-unit-and-rectifier/electrolysis-unit-b/electrolysis-unit-b.component';
import { LoadingPageComponent } from './pages/loading-page/loading-page.component';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'loading-screen', component: LoadingPageComponent,canActivate: [AuthGuard]},
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  {
    path: 'dashboard', component: DashboardComponent,canActivate: [AuthGuard],
    children: [
      { path: 'home', component: HomeComponent, },


      //Atul-100TPD-Electrolysis-Unit-and-Rectifier
      {path:'electrolysis-home', component: ElectrolysisUnitAndRectifierComponent},
      {path:'electrolysis-unit-A', component: ElectrolysisUnitAComponent},
      {path:'electrolysis-unit-B', component: ElectrolysisUnitBComponent},
      
      
    ]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
