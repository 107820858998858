<div style="position: relative; height: 100%; width: 100%">
  <Water-mark></Water-mark>

  <div style="
  width: 100%;
  height: 100%;
  min-width: 1300px;
  display: flex;
  flex-direction: column;
  position: absolute;">
    <div style="display: flex; flex-direction: row; height: 100%; width: 100%;">
      <!-- main body -->
      <div style="
      height: auto;
      width: 100%;
      display: flex;
      flex-direction: row;
      padding: 10px;
      align-items: start;
      gap: 10px;
      justify-content: center;
      overflow-y: scroll;
      padding-left: 100px;">
        <div style="
        height: 650px;
        width: 1300px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;">
          <!-- map -->
          <div style="
          height: 650px;
          display: flex;
          width: 1300px;
          align-items: center;
          justify-content: center;
          position: absolute;
          margin-top:0%;
          margin-left:-15%;">
          
            <img src="../../../assets/atul-assets/Atul Asset/ELECTROLYSIS UNIT AND RECTIFIER.svg" width="1300px" height="650px" />
          </div>
          <!-- componets -->
          <!-- buttons  -->
          <div style="height: 500px; width:1000px; position: relative; ">
            <div style="display:flex; margin-top: 190px; 
                margin-left: -658px;">
              <button style="
                  border-radius: 15px;
                  background-color: var(--blue-one);
                  background: radial-gradient(50% 50% at 50% 50%, #0043A6 0%, #002E71 100%);
                  border: none;
                  height: auto;
                  height: 30px;
                  width: 150px;
                  color: white;
                  cursor: pointer;
                  margin-top: 185px;
                  margin-left: 515px;
                  " routerLink="../electrolysis-unit-one">
                Electrolysis Unit 1
              </button>
            </div>

            <div style="display:flex; margin-top: -184px;
                margin-left: -488px;">
              <button style="
                  border-radius: 15px;
                  background-color: var(--blue-one);
                  background: radial-gradient(50% 50% at 50% 50%, #0043A6 0%, #002E71 100%);
                  border: none;
                  height: auto;
                  height: 30px;
                  width: 150px;
                  color: white;
                  cursor: pointer;margin-top: 315px;
                  margin-left: 515px;
                  " routerLink="../electrolysis-unit-two">
                  
                  Electrolysis Unit 2
              </button>
            </div>

            <div style="display:flex; margin-top: -505px; 
                margin-left: -205px;">
              <button style="
                  border-radius: 15px;
                  background-color: var(--blue-one);
                  background: radial-gradient(50% 50% at 50% 50%, #0043A6 0%, #002E71 100%);
                  border: none;
                  height: auto;
                  height: 30px;
                  width: 150px;
                  color: white;
                  cursor: pointer;margin-top: 315px;
                  margin-left: 515px;
                  " routerLink="../electrolysis-unit-three">
                  
                  Electrolysis Unit 3
              </button>
            </div>

            <div style="display:flex; margin-top: -185px; 
                margin-left: -34px;">
              <button style="
                  border-radius: 15px;
                  background-color: var(--blue-one);
                  background: radial-gradient(50% 50% at 50% 50%, #0043A6 0%, #002E71 100%);
                  border: none;
                  height: auto;
                  height: 30px;
                  width: 150px;
                  color: white;
                  cursor: pointer;margin-top: 315px;
                  margin-left: 515px;
                  " routerLink="../electrolysis-unit-four">
                  Electrolysis Unit 4
              </button>
            </div>

            <div style="display:flex; margin-top: -375px; 
                margin-left: 226px;">
              <button style="
                  border-radius: 15px;
                  background-color: var(--blue-one);
                  background: radial-gradient(50% 50% at 50% 50%, #0043A6 0%, #002E71 100%);
                  border: none;
                  height: auto;
                  height: 30px;
                  width: 150px;
                  color: white;
                  cursor: pointer;
                  margin-top: 185px;
                  margin-left: 515px;
                   " routerLink="../electrolysis-unit-five">
                   Electrolysis Unit 5
              </button>
            </div>

            <div style="display:flex; margin-top: -54px; 
                margin-left: 342px;">
              <button style="
                  border-radius: 15px;
                  background-color: var(--blue-one);
                  background: radial-gradient(50% 50% at 50% 50%, #0043A6 0%, #002E71 100%);
                  border: none;
                  height: auto;
                  height: 30px;
                  width: 150px;
                  color: white;
                  cursor: pointer;
                  margin-top: 185px;
                  margin-left: 515px;
                   " routerLink="../electrolysis-unit-six">
                   Electrolysis Unit 6
              </button>
            </div>

          </div>



          
        </div>

        


        <!-- KPI FORM  -->
        
          <!-- <div class="form" style="height: 200px; width: 300px; ">
            <span
              class="span-text-dark"
              style="font-weight: bold; text-align: center"
              >KEY PERFORMANCE INDICATOR</span
            >
  
            <div style="height: 30px"></div>
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
              "
            >
              <span
                class="span-text-dark"
                style="font-size: 12px; font-weight: 500"
                >OVERALL HVAC SAVING</span
              >
  
              <div class="input-group" style="width: 120px">
                <img src="/assets/images/power.png" />
                <input
                  
                />
                <span>%</span>
              </div>
            </div>
          </div>  -->
        
        
        
      </div>
    </div>
  </div>
</div>