<div class="side-bar-container" style="display: flex; flex-direction: row; height: 100%">
  <div class="main-side-bar">
    <div style="color: white; display: flex; flex-direction: column">
      <!-- <div class="icon-container" (click)="drawer.toggle(); ">
        <mat-icon>menu</mat-icon>
      </div> -->
      <div class="icon-container" routerLink="electrolysis-home">
        <mat-icon>home</mat-icon>
      </div>

      <div class="icon-container" (click)="goback()">
        <mat-icon>reply</mat-icon>
      </div>

      <!-- <div class="icon-container" routerLink="summary-sheet">
        <mat-icon>analytics</mat-icon>
      </div> -->

      <div class="icon-container">
        <mat-icon>code</mat-icon>
      </div>

      <div class="icon-container">
        <mat-icon>notifications</mat-icon>
      </div>
    </div>

    <div style="
        border-top: 1px solid white;
        color: white;
        display: flex;
        flex-direction: column;
      ">
      <div class="icon-container">
        <mat-icon>settings_outline</mat-icon>
      </div>

      <div class="icon-container">
        <mat-icon>error_outline</mat-icon>
      </div>
    </div>
  </div>

  <mat-drawer-container class="drawer-container">
    <mat-drawer #drawer class="drawer" mode="side" [opened]="showNavbar">
      <div class="drawer-content">
        <div class="drawer-header">Dashboard</div>
        <mat-divider></mat-divider>

        <mat-action-list>

          <ng-container *ngFor="let item of items">
            <button mat-list-item (click)="handleItemClick(item); selectedItem = item" [routerLink]="item.route"
              style="margin-left: 20px;">
              <span class="span-text-dark">{{ item.title }}</span>
            </button>
          </ng-container>




        </mat-action-list>
      </div>
    </mat-drawer>
  </mat-drawer-container>


</div>