import { Component } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {
  list=[
    {
      title:"Electrolysis Unit & Rectifier",
      route:'electrolysis-unit-and-rectifier',
      image:'/assets/images/electrolysis-process.svg'
    },
    {
      title:"HCL Synthesis & Absorption",
      route:'ahu2',
      // image:'/assets/images/ahu-icon.svg'
    },
    {
      title:"unit",
      route:'unit',
      image:'/assets/images/ahu-icon.svg'
    },
    
    // {
    //   title:"CHILLER PLANT",
    //   route:'chillerplant',
    //   image:'/assets/images/chiller-plant-icon.svg'
    // },
   
  ]
}
