
<div style="position: relative; height: 100%; width: 100%">
  <Water-mark></Water-mark>
  <div class="main_container">
    <button class="button-a7" routerLink="../electrolysis-unit-B">
      <img src="assets/logos/right-arrow.png" alt="right-arrow" style="width: 50%">
    </button>
    <mat-progress-bar mode="indeterminate" [ngClass]="{ 'show-progress': progress, 'hide-progress': !progress }"></mat-progress-bar>
    <div class="content-container">
      <div class="upcontainer">
        <!-- FAHU Map  -->
        <div class="map-container">
          <img class="fmap" src="../../../assets/atul-assets/Atul Asset/IIA001a - UNIT.svg" alt="Electrolysis" />

          <!-- Components -->
          <!-- <div class="component fan" style="height: 14.5%; top: 66.9%; left: 50.65%;">
            <Fan [state]="state" ></Fan>
          </div> -->
          <!-- Lean Brine FLow  -->
          <div class="input-groupR RHc component" style="display:flex; justify-content:center; align-items:center; top: 7.3%; left: 4.2%; width: 8.5%; height: 3%; padding-left: 0.5%; background-color:aquamarine;">
            <input style=" background-color:aquamarine; color: #D90606; font-weight:600" [ngModel]="Flow_of_lean_brine_A_in | number: '1.2-2'" (ngModelChange)="Flow_of_lean_brine_A_in = $event" />
            <span style="font-size: 80%; color: #D90606; font-weight:600" >Kg/hr</span>
          </div>
          <!--  Caustic Production  Flow -->
          <div class="input-groupR teampc component" style="display:flex; justify-content:center; align-items:center; top: 7.9%; left: 64%; width: 7%; height: 3%; padding-left: 0.5%; background-color:aquamarine;">
            <input style=" background-color:aquamarine; color: #D90606; font-weight:600" [ngModel]="Flow_rate_of_caustic_outlet_A_in | number: '1.2-2'" (ngModelChange)="Flow_rate_of_caustic_outlet_A_in = $event" />
            <span style="font-size: 80%; color: #D90606; font-weight:600" >Kg/hr</span>
          </div>

          <!--  Caustic Production  Flow -->
          <div class="input-groupR teampc component" style="display:flex; justify-content:center; align-items:center; top: 10.9%; left: 64%; width: 7%; height: 3%; padding-left: 0.5%; background-color:aquamarine;">
            <input style=" background-color:aquamarine; color: #00A578; font-weight:600" [ngModel]="Flow_rate_of_caustic_outlet_A_out | number: '1.2-2'" (ngModelChange)="Flow_rate_of_caustic_outlet_A_out = $event" />
            <span style="font-size: 80%; color: #00A578; font-weight:600" >Kg/hr</span>
          </div>
          <!--  Caustic Production  Temp -->
          <div class="input-groupR RHc component" style="display:flex; justify-content:center; align-items:center; top: 13.9%; left: 64%; width: 7%; height: 3%; padding-left: 0.5%; background-color:aquamarine;">
            <input style=" background-color:aquamarine; color: #D90606; font-weight:600" [ngModel]="Temp_of_caustic_outlet_A_in | number: '1.2-2'" (ngModelChange)="Temp_of_caustic_outlet_A_in = $event" />
            <span style="font-size: 80%; color: #D90606; font-weight:600" >°C</span>
          </div>
           <!--  Caustic Production  conc. NaOH% -->
           <div class="input-groupR RHc component" style="display:flex; justify-content:center; align-items:center; top: 17%; left: 64%; width: 7%; height: 3%; padding-left: 0.5%; background-color:aquamarine;">
            <input style=" background-color:aquamarine; color: #D90606; font-weight:600" [ngModel]="Concentration_of_caustic_outlet_A_in | number: '1.2-2'" (ngModelChange)="Concentration_of_caustic_outlet_A_in = $event" />
            <span style="font-size: 80%; color: #D90606; font-weight:600" >%</span>
          </div>
            <!-- Hydrogen Gas Flow  -->
            <div class="input-groupR RHc component" style="display:flex; justify-content:center; align-items:center; top: 33%; left: 54.1%; width: 7%; height: 3%; padding-left: 0.5%; background-color:aquamarine;">
              <input style=" background-color:aquamarine; color: #D90606; font-weight:600" [ngModel]="H2_outlet_flow_based_on_NaCl_flow_A_in | number: '1.2-2'" (ngModelChange)="H2_outlet_flow_based_on_NaCl_flow_A_in = $event" />
              <span style="font-size: 80%; color: #D90606; font-weight:600" >Kg/hr</span>
            </div>
          <!-- Hydrogen Gas Temp -->
          <div class="input-groupR RHc component" style="display:flex; justify-content:center; align-items:center; top: 36%; left: 54.1%; width: 7%; height: 3%; padding-left: 0.5%; background-color:aquamarine;">
            <input style=" background-color:aquamarine; color: #D90606; font-weight:600" [ngModel]="ELE_cathode_header_A_in | number: '1.2-2'" (ngModelChange)="ELE_cathode_header_A_in = $event" />
            <span style="font-size: 80%; color: #D90606; font-weight:600" >°C</span>
          </div>
           <!-- Hydrogen Gas Pressure -->
           <div class="input-groupR fanspeedc1 component" style="display:flex; justify-content:center; align-items:center; top: 39%; left: 54.1%; width: 7%; height: 3%; padding-left: 0.5%; background-color:aquamarine;">
            <input style=" background-color:aquamarine; color: #D90606; font-weight:600" [ngModel]="H2_pressure_A_in | number: '1.2-2'" (ngModelChange)="H2_pressure_A_in = $event" />
            <span style="font-size: 80%; color: #D90606; font-weight:600" >mmWC</span>
          </div>
            <!-- Chlorine Gas Flow  -->
            <div class="input-groupR fanspeedc2 component" style="display:flex; justify-content:center; align-items:center; top: 33.2%; left: 72.6%; width: 7%; height: 3%; padding-left: 0.5%; background-color:aquamarine;">
              <input style=" background-color:aquamarine; color: #D90606; font-weight:600" [ngModel]="Cl2_outlet_flow_based_on_NaCl_flow_A_in | number: '1.2-2'" (ngModelChange)="Cl2_outlet_flow_based_on_NaCl_flow_A_in = $event" />
              <span style="font-size: 80%; color: #D90606; font-weight:600" >Kg/hr</span>
            </div>
             <!-- Chlorine Gas Temp  -->
             <div class="input-groupR fanspeedc2 component" style="display:flex; justify-content:center; align-items:center; top: 36.25%; left: 72.6%; width: 7%; height: 3%; padding-left: 0.5%; background-color:aquamarine;">
              <input style=" background-color:aquamarine; color: #D90606; font-weight:600" [ngModel]="ELE_anode_header_A_in | number: '1.2-2'" (ngModelChange)="ELE_anode_header_A_in = $event" />
              <span style="font-size: 80%; color: #D90606; font-weight:600" >°C</span>
            </div>
              <!-- Chlorine Gas Pressure  -->
              <div class="input-groupR fanspeedc2 component" style="display:flex; justify-content:center; align-items:center; top: 39.25%; left: 72.6%; width: 7%; height: 3%; padding-left: 0.5%; background-color:aquamarine;">
                <input style=" background-color:aquamarine; color: #D90606; font-weight:600" [ngModel]="Cl2_pressure_A_in | number: '1.2-2'" (ngModelChange)="Cl2_pressure_A_in = $event" />
                <span style="font-size: 80%; color: #D90606; font-weight:600" >mmWC</span>
              </div>

              <!-- Ultra Pure Brine flow  -->
            <div class="input-groupR fanspeedc2 component" style="display:flex; justify-content:center; align-items:center; top: 70.2%; left: 5%; width: 7%; height: 3%; padding-left: 0.5%; background-color:aquamarine;">
              <input style=" background-color:aquamarine; color: #D90606; font-weight:600" [ngModel]="Flow_of_brine_A_in | number: '1.2-2'" (ngModelChange)="Flow_of_brine_A_in = $event" />
              <span style="font-size: 80%; color: #D90606; font-weight:600" >Kg/hr</span>
            </div>
             <!--  Ultra Pure Brine temp -->
             <div class="input-groupR fanspeedc2 component" style="display:flex; justify-content:center; align-items:center; top: 73.1%; left: 5%; width: 7%; height: 3%; padding-left: 0.5%; background-color:aquamarine;">
              <input style=" background-color:aquamarine; color: #D90606; font-weight:600" [ngModel]="Pure_brine_to_cell_plant_A_in | number: '1.2-2'" (ngModelChange)="Pure_brine_to_cell_plant_A_in = $event" />
              <span style="font-size: 80%; color: #D90606; font-weight:600" >°C</span>
            </div>
              <!--  Ultra Pure Brine conc.  -->
              <div class="input-groupR fanspeedc2 component" style="display:flex; justify-content:center; align-items:center; top: 76.1%; left: 5%; width: 7%; height: 3%; padding-left: 0.5%; background-color:aquamarine;">
                <input style=" background-color:aquamarine; color: #D90606; font-weight:600" [ngModel]="Concentration_of_saturated_brine_A_in | number: '1.2-2'" (ngModelChange)="Concentration_of_saturated_brine_A_in = $event" />
                <span style="font-size: 80%; color: #D90606; font-weight:600" >%</span>
              </div>

               <!-- DM Water Flow -->
               <div class="input-groupR fanspeedc2 component" style="display:flex; justify-content:center; align-items:center; top: 79.25%; left: 31.5%; width: 7%; height: 3%; padding-left: 0.5%; background-color:aquamarine;">
                <input style=" background-color:aquamarine; color: #D90606; font-weight:600" [ngModel]="Total_dm_water_flow_to_electrolyser_A_in | number: '1.2-2'" (ngModelChange)="Total_dm_water_flow_to_electrolyser_A_in = $event" />
                <span style="font-size: 80%; color: #D90606; font-weight:600" >Kg/hr</span>
              </div>
                <!-- DM Water Temp  -->
                <div class="input-groupR fanpowc component" style="display:flex; justify-content:center; align-items:center; top: 82.25%; left: 31.5%; width: 7%; height: 3%; padding-left: 0.5%; background-color:aquamarine;">
                  <input style=" background-color:aquamarine; color: #D90606; font-weight:600" [ngModel]="Temp_of_dm_feed_water_A_in | number: '1.2-2'" (ngModelChange)="Temp_of_dm_feed_water_A_in = $event" />
                  <span style="font-size: 80%; color: #D90606; font-weight:600" >°C</span>
                </div>
                <!-- DIluted Caustic Flow -->
                <div class="input-groupR fanspeedc2 component" style="display:flex; justify-content:center; align-items:center; top: 72.8%; left: 64.5%; width: 7%; height: 3%; padding-left: 0.5%; background-color:aquamarine;">
                  <input style=" background-color:aquamarine; color: #D90606; font-weight:600" [ngModel]="Elect_caustic_feed_A_in | number: '1.2-2'" (ngModelChange)="Elect_caustic_feed_A_in = $event" />
                  <span style="font-size: 80%; color: #D90606; font-weight:600" >Kg/hr</span>
                </div>
                  <!-- DIluted Caustic Temp  -->
                <div class="input-groupR fanpowc component" style="display:flex; justify-content:center; align-items:center; top: 75.8%; left: 64.5%; width: 7%; height: 3%; padding-left: 0.5%; background-color:aquamarine;">
                  <input style=" background-color:aquamarine; color: #D90606; font-weight:600" [ngModel]="Temp_of_caustic_feed_to_catholyte_A_in | number: '1.2-2'" (ngModelChange)="Temp_of_caustic_feed_to_catholyte_A_in = $event" />
                  <span style="font-size: 80%; color: #D90606; font-weight:600" >°C</span>
                </div>

                <!-- Power  -->
                <div class="input-groupR fanpowc component" style="display:flex; justify-content:center; align-items:center; top: 93.1%; left: 18%; width: 7%; height: 3%; padding-left: 0.5%; background-color:aquamarine;">
                  <input style=" background-color:aquamarine; color: #D90606; font-weight:600" [ngModel]="Power_A_in | number: '1.2-2'" (ngModelChange)="Power_A_in = $event" />
                  <span style="font-size: 80%; color: #D90606; font-weight:600" >kW</span>
                </div>
                <!-- Power  -->
                <!-- <div class="input-groupR fanpowc component" style="display: flex; justify-content: center; align-items: center; top: 96.1%; left: 18%; width: 7%; height: 3%; padding-left: 0.5%; background-color: aquamarine;">
                  <input style=" background-color:aquamarine; color: #00A578; font-weight:600" [ngModel]="Power_A_out*1000 | number: '1.2-2'" (ngModelChange)="Power_A_out = $event" />
                  <span style="font-size: 80%; color: #00A578; font-weight:600" >kW</span>
                </div> -->
                <div class="input-groupR fanpowc component" style="display: flex; justify-content: center; align-items: center; top: 96.1%; left: 18%; width: 7%; height: 3%; padding-left: 0.5%; background-color: aquamarine;">
                  <input style=" background-color:aquamarine; color: #00A578; font-weight:600" [ngModel]="(Power_A_in)-(power_saving_A*Power_A_in)/300 | number: '1.2-2'" (ngModelChange)="Power_A_out = $event" />
                  <span style="font-size: 80%; color: #00A578; font-weight:600" >kW</span>
                </div>
                <!-- Voltage -->
                <div class="input-groupR fanspeedc2 component" style="display:flex; justify-content:center; align-items:center; top: 93.1%; left: 33%; width: 7%; height: 3%; padding-left: 0.5%; background-color:aquamarine;">
                  <input style=" background-color:aquamarine; color: #D90606; font-weight:600" [ngModel]="Rect_voltage_A_in | number: '1.2-2'" (ngModelChange)="Rect_voltage_A_in = $event" />
                  <span style="font-size: 80%; color: #D90606; font-weight:600" >V</span>
                </div>
                  <!-- Current  -->
                <div class="input-groupR fanpowc component" style="display:flex; justify-content:center; align-items:center; top: 93.1%; left: 47%; width: 7%; height: 3%; padding-left: 0.5%; background-color:aquamarine;">
                  <input style=" background-color:aquamarine; color: #D90606; font-weight:600" [ngModel]="Rect_current_A_in | number: '1.2-2'" (ngModelChange)="Rect_current_A_in = $event" />
                  <span style="font-size: 80%; color: #D90606; font-weight:600" >KA</span>
                </div>


          <div class="form component">
            <span class="span-text-dark">Key Performance Indicator</span>

            <!-- <div style="height: 40px;"></div> -->
            <div  class="hvac-text" style="background-color: white;">
            <span class="span-text-dark" style="display:flex; font-size: 110%; text-align:left; font-weight: 500; margin-left:0%; align-items:center;">Caustic Production Improvement</span>
            <div style="width:25%; ">
                <input style="  width:50px; font-size: 16px; text-align:center; border-radius:8px; border: 2px solid #C4C4C4  "
                [ngModel]="caustic_production/2 | number : '1.2-2'"
                (ngModelChange)="caustic_production = $event" />
            </div>
            <span style="margin-right:10%; font-size: 100%;">%</span>
            </div>

            <div  class="hvac-text" style="background-color: white;">
              <span class="span-text-dark" style="display:flex; font-size: 110%; text-align:left; font-weight: 500; align-items:center;">Power Saving</span>
              <div style=" margin-left:22%; ">
                  <input style="  width:50px; font-size: 16px; text-align:center; border-radius:8px; border: 2px solid #C4C4C4  "
                  [ngModel]="power_saving_A/3 | number : '1.2-2'"
                  (ngModelChange)="power_saving_A = $event" />
              </div>
              <span style="margin-right:10%; font-size: 100%;">%</span>
              </div>
        </div>

        </div>
      </div>
    </div>
  </div>
</div>
