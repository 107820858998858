import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-electrolysis-unit-b',
  templateUrl: './electrolysis-unit-b.component.html',
  styleUrls: ['./electrolysis-unit-b.component.css'],
})
export class ElectrolysisUnitBComponent {
  type!: number;
  predict_response: any = {};
  predict_input: any = {};
  optimise_input: any = {};
  optimise_response: any = {};
  progress: boolean = false;
  disableOptimise: boolean = true;
  electrolyser_out_B: any;
  electrolyser_out_A: any;
  electrolyser_input_B: any;
  // input form fields
  Elect_caustic_feed_B: number = 0;               //input-3
  Rect_voltage_B: number = 0;                     //input-8
  Rect_current_B: number = 0;                    //input-9
  Pure_brine_to_cell_plant_B: number = 0;         //input-5
  Cl2_pressure_B: number = 2000;   
  H2_pressure_B: number = 2000;   
  ELE_anode_header_B: number = 85;   
  ELE_cathode_header_B: number = 85;   
  Temp_of_dm_feed_water_B: number = 0;            //input-6
  Temp_of_caustic_outlet_B: number = 90;
  Temp_of_caustic_feed_to_catholyte_B: number = 0;   //input-7
  Concentration_of_saturated_brine_B: number = 0;   //input-4
  Concentration_of_caustic_outlet_B: number = 32;
  Total_dm_water_flow_to_electrolyser_B: number = 0;    //input-2
  Flow_rate_of_caustic_outlet_B: number = 15000;
  Flow_of_brine_B: number = 0;                       //input-1
  H2_outlet_flow_based_on_NaCl_flow_B: number = 500;
  Cl2_outlet_flow_based_on_NaCl_flow_B: number = 17000;
  Flow_of_lean_brine_B: number = 15000;
  model_no: number = 1;

  // output form fields
  // Ultra Pure Brine 
  Flow_of_brine_B_in: number = 0;
  Pure_brine_to_cell_plant_B_in: number = 0;
  Concentration_of_saturated_brine_B_in: number = 0;
  // Lean Brine 
  Flow_of_lean_brine_B_in: number = 0;
  // Hydrogen Gas 
  H2_pressure_B_in: number = 0;
  H2_outlet_flow_based_on_NaCl_flow_B_in: number = 0;
  // Chlorine Gas 
  Cl2_pressure_B_in: number = 0;
  Cl2_outlet_flow_based_on_NaCl_flow_B_in: number = 0;
  // Caustic Production 
  Flow_rate_of_caustic_outlet_B_in: number = 0;
  Temp_of_caustic_outlet_B_in: number = 0;
  Concentration_of_caustic_outlet_B_in: number = 0;
  // DIluted Caustic 
  Elect_caustic_feed_B_in: number = 0;
  Temp_of_caustic_feed_to_catholyte_B_in: number = 0;
  // DM Water 
  Total_dm_water_flow_to_electrolyser_B_in: number = 0;
  Temp_of_dm_feed_water_B_in: number = 0;
  Power_B_in: number = 0;
  Rect_voltage_B_in: number = 0;
  Rect_current_B_in: number = 0;


  ELE_anode_header_B_in: number = 0;
  ELE_cathode_header_B_in: number = 0;

  Flow_rate_of_caustic_outlet_B_out: number=0;
  Elect_caustic_feed_B_out: number = 0;
  Flow_of_brine_B_out: number = 0;
  Power_B_out: number = 0;
  Rect_current_B_out: number = 0;
  Rect_voltage_B_out: number = 0;
  Total_dm_water_flow_to_electrolyser_B_out: number = 0;
  interfaceResult: any = {};
  predictionResult: any = {};
  jsonData: any = {};
  caustic_production:number=0;
  power_saving_B:number=0;


  hasdata: boolean = true;

  // KPI DATA
  KPI: number = 0;

  constructor(
    private activeroute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private snackBar: MatSnackBar
  ) {
    if(localStorage.getItem('predictionResultB') && localStorage.getItem('interface_inputB') ){
      this.interfaceResult=localStorage.getItem('interface_inputB');
      this.interfaceResult=JSON.parse(this.interfaceResult);
      this.predictionResult=localStorage.getItem('predictionResultB');
      this.predictionResult=JSON.parse(this.predictionResult);
      this.Power_B_in=this.interfaceResult['Power_B'];
      this.Rect_current_B_out = this.predictionResult['Rect_current_B'];
      this.Rect_voltage_B_out = this.predictionResult['Rect_voltage_B'];
      this.Power_B_out = ((this.Rect_current_B_out)*(this.Rect_voltage_B_out));
              
      this.power_saving_B=(((this.Power_B_in-this.Power_B_out)/this.Power_B_in)*100);

      this.Flow_rate_of_caustic_outlet_B_in = this.interfaceResult['Flow_rate_of_caustic_outlet_B'];
      this.Flow_rate_of_caustic_outlet_B_out = this.predictionResult['Flow_of_brine_B']*0.684;
      this.caustic_production=(-1)*(((this.Flow_rate_of_caustic_outlet_B_in-this.Flow_rate_of_caustic_outlet_B_out)/this.Flow_rate_of_caustic_outlet_B_in)*100);
      
      if(this.caustic_production<0) {
        this.caustic_production=(-1)*this.caustic_production;
      }
      if(this.power_saving_B<0) {
        this.power_saving_B=(-1)*this.power_saving_B;
      }


    }else{
      this.caustic_production = 0;
      this.power_saving_B=0;
    }



    this.disableOptimise = !this.hasdata;

    console.log('hasdata', this.hasdata);
    console.log('disable', this.disableOptimise);
  }

  ngOnInit(): void {
    this.getPredictionResult();
    setInterval(() => {
      this.getPredictionResult();
    }, 240000); // 240000 milliseconds = 4 minutes
    this.getInputResult();
  }
  async getInputResult() {
    this.http.get<any>('https://bpsliveatchloralkali.bertlabs.com/get_result')
      .subscribe(
        (response: any) => {
          try {
            // Log the response to check its content
            console.log('Interface response:', response);
  
            // Check if the response is a string and parse it if necessary
            if (typeof response.result === 'string') {
              this.interfaceResult = JSON.parse(response.result);
            } else {
              this.interfaceResult = response.result;
            }
            this.Cl2_outlet_flow_based_on_NaCl_flow_B_in = this.interfaceResult['Cl2_outlet_flow_based_on_NaCl_flow_B'];
            this.Cl2_pressure_B_in = this.interfaceResult['Cl2_pressure_B'];
            this.Concentration_of_caustic_outlet_B_in = this.interfaceResult['Concentration_of_caustic_outlet_B'];
            this.Concentration_of_saturated_brine_B_in = this.interfaceResult['Concentration_of_saturated_brine_B'];
            this.ELE_anode_header_B_in = this.interfaceResult['ELE_anode_header_B'];
            this.ELE_cathode_header_B_in = this.interfaceResult['ELE_cathode_header_B'];
            this.Elect_caustic_feed_B_in = this.interfaceResult['Elect_caustic_feed_B'];
            this.Flow_of_brine_B_in = this.interfaceResult['Flow_of_brine_B'];
            this.Flow_of_lean_brine_B_in = this.interfaceResult['Flow_of_lean_brine_B'];
            this.Flow_rate_of_caustic_outlet_B_in = this.interfaceResult['Flow_rate_of_caustic_outlet_B'];
            this.H2_outlet_flow_based_on_NaCl_flow_B_in = this.interfaceResult['H2_outlet_flow_based_on_NaCl_flow_B'];
            this.H2_pressure_B_in = this.interfaceResult['H2_pressure_B'];
            this.Power_B_in = this.interfaceResult['Power_B'];
            this.Pure_brine_to_cell_plant_B_in = this.interfaceResult['Pure_brine_to_cell_plant_B'];
            this.Rect_current_B_in = this.interfaceResult['Rect_current_B'];
            this.Rect_voltage_B_in = this.interfaceResult['Rect_voltage_B'];
            this.Temp_of_caustic_feed_to_catholyte_B_in = this.interfaceResult['Temp_of_caustic_feed_to_catholyte_B'];
            this.Temp_of_caustic_outlet_B_in = this.interfaceResult['Temp_of_caustic_outlet_B'];
            this.Temp_of_dm_feed_water_B_in = this.interfaceResult['Temp_of_dm_feed_water_B'];
            this.Total_dm_water_flow_to_electrolyser_B_in = this.interfaceResult['Total_dm_water_flow_to_electrolyser_B'];
            console.log('Interface result:', this.interfaceResult);
            this.jsonData['interfaceResult'] = this.interfaceResult;
            console.log(this.jsonData);
            localStorage.setItem('interface_inputB', JSON.stringify(this.interfaceResult));
            console.log('interafce_output', this.predict_response);
          } catch (error) {
            console.error('Error parsing interface result:', error);
          }
        },
        (error) => {
          console.error('Error fetching interface result:', error);
          if (error.error instanceof ErrorEvent) {
            // Client-side or network error
            console.error('Client-side error:', error.error.message);
          } else {
            // Backend returned an unsuccessful response code
            console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
          }
        }
      );
  }

  async getPredictionResult() {
    this.http.get<any>('https://bpsliveatchloralkali.bertlabs.com/get_prediction_result')
      .subscribe(
        (response: any) => {
          try {
            // Log the response to check its content
            console.log('Prediction response:', response);
  
            // Check if the response is a string and parse it if necessary
            if (typeof response.result === 'string') {
              this.predictionResult = JSON.parse(response.result);
            } else {
              this.predictionResult = response.result;
            }
            // this.Elect_caustic_feed_B_out = this.predictionResult['Elect_caustic_feed_B'];
            this.Flow_rate_of_caustic_outlet_B_out=this.predictionResult['Flow_of_brine_B']*0.684;
            this.Flow_of_brine_B_out = this.predictionResult['Flow_of_brine_B'];
            this.Rect_current_B_out = this.predictionResult['Rect_current_B'];
            this.Rect_voltage_B_out = this.predictionResult['Rect_voltage_B'];
            this.Power_B_out = (((this.Rect_current_B_out)*(this.Rect_voltage_B_out))/1000);
            this.Total_dm_water_flow_to_electrolyser_B_out = this.predictionResult['Total_dm_water_flow_to_electrolyser_B'];

            this.KPI = (((this.Power_B_in - this.Power_B_out)/this.Power_B_in)*100);

            console.log('Prediction result:', this.predictionResult);
            this.jsonData['predictionResult'] = this.predictionResult;
            console.log(this.jsonData);
            localStorage.setItem('predictionResultB', JSON.stringify(this.predictionResult));
            console.log('predict_output', this.predict_response);
          } catch (error) {
            console.error('Error parsing prediction result:', error);
          }
        },
        (error) => {
          console.error('Error fetching prediction result:', error);
          if (error.error instanceof ErrorEvent) {
            // Client-side or network error
            console.error('Client-side error:', error.error.message);
          } else {
            // Backend returned an unsuccessful response code
            console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
          }
        }
      );
  }
  


  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };




  // Elect_caustic_feed_B: number = 0;               //input-5
  // Rect_voltage_B: number = 0;                     //input-8
  // Rect_current_B: number = 0;                    //input-9
  // Pure_brine_to_cell_plant_B: number = 0;         //input-2   
  // Temp_of_dm_feed_water_B: number = 0;            //input-4
  // Temp_of_caustic_feed_to_catholyte_B: number = 0;   //input-6
  // Concentration_of_saturated_brine_B: number = 0;   //input-7
  // Total_dm_water_flow_to_electrolyser_B: number = 0;    //input-3
  // Flow_of_brine_B: number = 0;                       //input-1
  // Validation
 
  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: 'start',
      duration: 5000,
    });
  }

  

 
}
