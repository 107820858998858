import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'Card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent {

  @Input() item:any;
  @Input() itemId!: string;
  @Input() title!:string;
  @Input() image!:string;
  @Input() disabled!:boolean;
  
  constructor(private router: Router, private http: HttpClient) { }

  ngOnInit(): void {
    // Fetch data from the backend based on the itemId
    this.http.get<any>('YOUR_BACKEND_ENDPOINT/' + this.itemId).subscribe(
      (data) => {
        this.item = data;
      },
      (error) => {
        console.error('Error fetching data:', error);
      }
    );
  }


}
