import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import {ErrorStateMatcher} from '@angular/material/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})


export class LoginComponent {
  @ViewChild('form') form!: NgForm;
  email:string;
  password:string;

  usernameFormControl = new FormControl('', [Validators.required]);
  passwordFormControl = new FormControl('', [Validators.required]);
 
  matcher = new MyErrorStateMatcher();

  public $error = new BehaviorSubject<{
    message: string;
    status: number;
  } | null>(null);

  constructor(
    public authService: AuthService,
    private router: Router,
    private snackBar:MatSnackBar
  ) {
    this.email="";
    this.password="";
  }


  openSnackBar(message:string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition:"start",
      duration: 5000
    });
    
  }




  handleLogin(): void { 

    if (this.authService.login(this.email, this.password)) { 

      this.router.navigate(['./loading-screen']).then(() => { 

        setTimeout(() => { 

          this.router.navigate(['/dashboard/electrolysis-home']); 

          this.openSnackBar('Successfully Login'); 

        }, 5000); 

      }); 

    } else if (this.email.length != 0 && this.password.length != 0) { 

      this.openSnackBar('Invalid Credentials'); 

    } 

  } 

  ngOnInit(): void {
    // this.authService.isAuthenticated().subscribe(isAuth => {
    //   // goto app if already logged in
    //   if (isAuth) this.router.navigate(['app']);
    // });
  }
}
