import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { NgIf } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'moonshot-logout-dialog',
  templateUrl: './logout-dialog.component.html',
  standalone: true,
  imports: [MatDialogModule, NgIf],
  styleUrls: ['./logout-dialog.component.css'],
})
export class LogoutDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<LogoutDialogComponent>,
    private router: Router
  ) {}

  logout(): void {
    this.router.navigate(['/login']);
  }
}
