import { Component, Inject, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { LogoutDialogComponent } from '../logout-dialog/logout-dialog.component';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'Nav-bar',
  standalone: true,
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css'],
  imports: [MatButtonModule, MatDialogModule],
})
export class NavBarComponent {
  title: any;

  constructor(public dialog: MatDialog, private router: Router) {
    

    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        var endpoint=this.cropUrl(event.url);
        this.title = this.url_dictionary.get(endpoint);
      }
    });
  }

  showLogoutDialog() {
    const dialogRef = this.dialog.open(LogoutDialogComponent, {
      width: '350px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }

  cropUrl(url: string): string {
    var splitted = url.split('/');
    return splitted[splitted.length - 1];
  }


  url_dictionary = new Map<string, string>([
    ['home', 'HOME'],
    ['electrolysis-home', 'ELECTROLYSIS UNIT AND RECTIFIER'],
    ['electrolysis-unit-A', 'IIA001A - UNIT'],
    ['electrolysis-unit-B', 'IIA001B - UNIT'],
    ['electrolysis-unit-one','ELECTROLYSIS UNIT 1'],
    // ['summary-sheet', 'SUMMARY SHEET'],
    // ['electrolysis-unit-two','ELECTROLYSIS UNIT 2'],
    // ['electrolysis-unit-three','ELECTROLYSIS UNIT 3'],
    // ['electrolysis-unit-four','ELECTROLYSIS UNIT 4'],
    // ['electrolysis-unit-five','ELECTROLYSIS UNIT 5'],
    // ['electrolysis-unit-six','ELECTROLYSIS UNIT 6'],
    ['electrolysis-unit-and-rectifier','ELECTROLYSIS UNIT']

  ]);
  
}
