import { Injectable } from '@angular/core'; 

import { Router } from '@angular/router'; 

  

@Injectable({ 

  providedIn: 'root' 

}) 

export class AuthService { 

  private users = [ 

    { username: 'bertlabs', password: 'bert_admin', role: 'admin' }, 

    { username: 'atul', password: 'bert_atul', role: 'restricted' } 

  ]; 

  private currentUser: any = null; 

  

  constructor(private router: Router) { 

    const savedUser = sessionStorage.getItem('currentUser'); 

    if (savedUser) { 

      this.currentUser = JSON.parse(savedUser); 

    } 

   } 

  

  login(username: string, password: string): boolean { 

    const user = this.users.find(u => u.username === username && u.password === password); 

    if (user) { 

      this.currentUser = user; 

      sessionStorage.setItem('currentUser', JSON.stringify(this.currentUser)); 

      return true; 

    } 

    return false; 

  } 

  

  logout(): void { 

    this.currentUser = null; 

    sessionStorage.removeItem('currentUser'); 

    this.router.navigate(['/login']); 

  } 

  

  isLoggedIn(): boolean { 

    return this.currentUser !== null; 

  } 

  

  getUserRole(): string { 

    return this.currentUser ? this.currentUser.role : null; 

  } 

} 