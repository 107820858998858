<div
  style="
    height: 100vh;
    width: 100vw;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  "
>
  <div style="height: 100%; width: 100%; position: relative">
    <div
      style="
        height: 70%;
        width: 100%;
        background:url('/assets/images/client-image-auth(2).png');
        position: absolute;
        object-fit: cover;
        background-repeat: no-repeat;
        background-size: cover;
      "
    ></div>

    <div
      style="
        height: 100%;
        width: 100%;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      "
    >
      <div
        style="
          width: 90%;
          max-width: 500px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: start;
          gap: 20px;
        "
      >
<!--        

        <img
        src="/assets/logos/client-logo.png"
        width="300px"
        style=" border-radius: 5px"
      />
     -->
<!-- 
      <span class="span-text-light">powered by:</span>

      <div style="background-color: white;border-radius: 10px;padding: 5px 10px 5px 10px;">
        <img src="/assets/logos/bertlabs-logo.png" width="200px" />

      </div> -->
      
      <div style="width: 500px;background: #ffffff; border-radius: 20px ;height: 23vh; display: flex; justify-content: center; align-items: center;">
        <!-- <div style="align-items: center; "> -->
          <!-- <img src="/assets/logos/atul.png" style=" width:350px ;height: auto; object-fit: cover;" /> -->
           <div style="text-align: center;font-size: 36px;font-weight: 600;color: rgb(11, 43, 230);; width:350px ;height: auto;">Chlor Alkali</div>
        <!-- </div> -->
        <div style="position: absolute; top: 38%; left: 50%; transform: translate(-50%, -50%);">
          <img src="/assets/logos/powered_by.gif" width="280px" />
        </div>
      </div>
      

       
        <form class="form" (ngSubmit)="handleSubmit()" style="height: 310px">
        
          <div
            style="
              width: 100%;
              display: flex;
              flex-direction: column;
              gap: 15px;
              justify-content: center;
              align-items: center;
            "
          >
            <div class="span-text-dark" style="text-align: center">
              Enter your registered email to reset your password
            </div>
            <mat-form-field style="width: 100%" appearance="outline">
              <mat-label>Email</mat-label>
              <input
                type="text"
                matInput
                [formControl]="emailFormControl"
                [errorStateMatcher]="matcher"
                placeholder="Email"
                style="font-size: 14px"
              />

              <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                Please enter a valid email address
              </mat-error>

              <mat-error *ngIf="emailFormControl.hasError('required')">
                Email is <strong>required</strong>
              </mat-error>
            </mat-form-field>

            <button
              mat-raised-button
              style="
                width: 100%;
                background-color: #505549;
                height: 45px;
                color: white;
                font-family: sans-serif;
              "
              type="submit"
            >
              Send Reset Link
            </button>

            <a class="hover-text-dark" [routerLink]="['/login']">Go to Login</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
