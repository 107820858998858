<div style="position: relative; height: 100%; width: 100%">
    <Water-mark></Water-mark>
  
    <div style="
          width: 100%;
          height: 100%;
          min-width: 1200px;
          display: flex;
          flex-direction: column;
          position: absolute; ">
      <!-- <mat-progress-bar mode="indeterminate" [ngClass]="{ 'show-progress': progress, 'hide-progress': !progress }" ></mat-progress-bar> -->
      <div style="
            height: auto;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 10px;
            align-items: center;
            gap: 10px;
          ">
        <div style="
              height: 450px;
              width: 1400px;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
            ">
          <div style="
                height: 450px;
                width: 1400px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                margin-top:0px;
                margin-left:50px;
                z-index:-1;
              ">
            <img src="../../../assets/images/electrolysis-unit.svg" alt="Electrolysis" width="1400px" height="450px" />
          </div>
  
          <div style="position:relative;  ">
  
            <!-- Power  -->
            <div
              style=" position:absolute; margin-left:-280px; margin-top:-195px; justify-content:center; align-items:center; ">
              <div style="
                width: 220px;
                height: 35px;
                padding: 7px 7px 7px 7px;
                border-radius: 5px;
                background: linear-gradient(91.38deg, #D1EEFF 2.15%, #B9E5FF 100%);">
                <div style="display: flex;  gap:10px;  align-items:center; justify-content:space-between;">
                  <span>Power</span>
                  <div class="input-group" style="width: 100px;  ">
                    <input />
                    <div class="input-group-formula">kW</div>
                  </div>
                </div>
              </div>
            </div>
  
            <!-- Inlet Brine Flow  -->
            <div style=" position:absolute; margin-left: -720px; margin-top: -125px;">
              <div style="
                width: 220px;
                height: 35px;
                padding: 7px 7px 7px 7px;
                border-radius: 5px;
                background: linear-gradient(91.38deg, #D1EEFF 2.15%, #B9E5FF 100%);">
                <div style="display: flex;  gap:10px;  align-items:center; justify-content:space-between;">
                  <span>Inlet Brine</span>
                  <div class="input-group" style="width: 110px;">
                    <input />
                    <div class="input-group-formula">m³/hr</div>
                  </div>
                </div>
              </div>
            </div>
  
            <!-- Inlet Temp  -->
            <div style=" position:absolute; margin-left: -720px; margin-top: -88px;">
              <div style="
                width: 220px;
                height: 35px;
                padding: 7px 7px 7px 7px;
                border-radius: 5px;
                background: linear-gradient(91.38deg, #D1EEFF 2.15%, #B9E5FF 100%);">
                <div style="display: flex;  gap:10px;  align-items:center; justify-content:space-between;">
                  <span>Inlet Temp.</span>
                  <div class="input-group" style="width: 110px;">
                    <input />
                    <div class="input-group-formula">°C</div>
                  </div>
                </div>
              </div>
            </div>
  
            <!-- H2 Flow  -->
            <div style=" position:absolute; margin-left: 490px;margin-top: -103px;">
              <div style="
                width: 220px;
                height: 35px;
                padding: 7px 7px 7px 7px;
                border-radius: 5px;
                background: linear-gradient(91.38deg, #D1EEFF 2.15%, #B9E5FF 100%);">
                <div style="display: flex;  gap:10px;  align-items:center; justify-content:space-between;">
                  <span>Hydrogen</span>
                  <div class="input-group" style="width: 110px;">
                    <input />
                    <div class="input-group-formula">m³/hr</div>
                  </div>
                </div>
              </div>
            </div>
  
  
            <!-- CL2 Flow  -->
            <div style=" position:absolute; margin-left: 490px;margin-top: -66.5px;">
              <div style="
                width: 220px;
                height: 35px;
                padding: 7px 7px 7px 7px;
                border-radius: 5px;
                background: linear-gradient(91.38deg, #D1EEFF 2.15%, #B9E5FF 100%);">
                <div style="display: flex;  gap:10px;  align-items:center; justify-content:space-between;">
                  <span>Chlorine</span>
                  <div class="input-group" style="width: 110px;">
                    <input />
                    <div class="input-group-formula">m³/hr</div>
                  </div>
                </div>
              </div>
            </div>
  
  
            <!-- Lean NaCl Flow  -->
            <div style=" position:absolute; margin-left: -700px;margin-top: 137px;">
              <div style="
                width: 220px;
                height: 35px;
                padding: 7px 7px 7px 7px;
                border-radius: 5px;
                background: linear-gradient(91.38deg, #D1EEFF 2.15%, #B9E5FF 100%);">
                <div style="display: flex;  gap:10px;  align-items:center; justify-content:space-between;">
                  <span>Lean NaCl</span>
                  <div class="input-group" style="width: 110px;">
                    <input />
                    <div class="input-group-formula">m³/hr</div>
                  </div>
                </div>
              </div>
            </div>
  
            <!-- Inlet NaOH (dilute)  -->
            <div style=" position:absolute; margin-left: 490px;margin-top: 143px;">
              <div style="
                width: 220px;
                height: 35px;
                padding: 7px 7px 7px 7px;
                border-radius: 5px;
                background: linear-gradient(91.38deg, #D1EEFF 2.15%, #B9E5FF 100%);">
                <div style="display: flex;  gap:10px;  align-items:center; justify-content:space-between;">
                  <span style="line-height: 13px; align-items:center; justify-content:center;">Inlet NaOH <br> (dilute)</span>
                  <div class="input-group" style="width: 110px;">
                    <input />
                    <div class="input-group-formula">m³/hr</div>
                  </div>
                </div>
              </div>
            </div>
  
            <!--  NaOH FLow  -->
            <div style=" position:absolute; margin-left: 490px;margin-top: 180px;">
              <div style="
                width: 220px;
                height: 35px;
                padding: 7px 7px 7px 7px;
                border-radius: 5px;
                background: linear-gradient(91.38deg, #D1EEFF 2.15%, #B9E5FF 100%);">
                <div style="display: flex;  gap:10px;  align-items:center; justify-content:space-between;">
                  <span> Outlet NaOH</span>
                  <div class="input-group" style="width: 110px;">
                    <input />
                    <div class="input-group-formula">m³/hr</div>
                  </div>
                </div>
              </div>
            </div>
  
            <!-- Outlet Temp  -->
            <div style=" position:absolute; margin-left: 490px;margin-top: 217px;">
              <div style="
                width: 220px;
                height: 35px;
                padding: 7px 7px 7px 7px;
                border-radius: 5px;
                background: linear-gradient(91.38deg, #D1EEFF 2.15%, #B9E5FF 100%);">
                <div style="display: flex;  gap:10px;  align-items:center; justify-content:space-between;">
                  <span>Outlet Temp.</span>
                  <div class="input-group" style="width: 110px;">
                    <input />
                    <div class="input-group-formula">°C</div>
                  </div>
                </div>
              </div>
            </div>
  
  
  
  
  
          </div>
  
  
  
        </div>
  
  
        <div style="display: flex; flex-direction:row; gap:30px; justify-content:start; margin-top:50px;">
  
  
          <!-- Input Form  and KPI Indicator-->
  
          <div class="form" style="
              padding: 10px;
              height:150px;
              flex-direction: row;
              gap: 20px;
              justify-content:center;
              align-items: center;
            ">
          <!-- Input Indicator  -->
            <div style="
              width: 80%;
              display: flex;
              flex-direction: column;
              gap: 10px;
              align-items:center;
            ">
            
              <div style="display: flex;
                justify-content: center;
                align-items: center;  
                line-height: 26px;
                text-align: center;
                ">
                <p style="font-size: 22px; color: #312E5E;  font-weight: 400;">Input Data</p>
              </div>
              <!-- Inlet Feed temp. -->
              <div style="display: flex; flex-direction:row; gap:40px;">
                <div style="display: flex; flex-direction:column;">
                  <p style="display: flex;  line-height:5px;">Inlet Feed temp.</p>
                  <div class="input-group" style="width: 110px;">
                    <input />
                    <div class="input-group-formula-2">°C</div>
                  </div>
                </div>
                <!-- Inlet Brine Flow -->
                <div style="display: flex; flex-direction:column;">
                  <p style="display: flex; line-height:5px; ">Inlet Brine Flow</p>
                  <div class="input-group" style="width: 110px;">
                    <input />
                    <div class="input-group-formula-2">m³/hr</div>
                  </div>
                </div>
                <!-- dil. NaOH Flow -->
                <div style="display: flex; flex-direction:column;">
                  <p style="display: flex; line-height:5px; ">dil. NaOH Flow</p>
                  <div class="input-group" style="width: 110px;">
                    <input />
                    <div class="input-group-formula-2">m³/hr</div>
                  </div>
                </div>
                <!-- Power -->
                <div style="display: flex; flex-direction:column;">
                  <p style="display: flex; line-height:5px; ">Power</p>
                  <div class="input-group" style="width: 110px;">
                    <input />
                    <div class="input-group-formula-2">kW</div>
                  </div>
                </div>
              </div>
  
            </div>
  
  
            <div style="
                    display: flex;
                    flex-direction: column;
                    width: 20%;
                    gap: 20px;
                    justify-content: center;
                    align-items:center;
                    margin-top: 10px;
                  ">
                  <!-- Predict Button  -->
              <button style="
                      width: 80%;
                      background-color: var(--blue-one);
                      border-radius: 20px;
                      border: none;
                      height: auto;
                      padding: 7px;
                      color: white;
                      cursor: pointer;
                    " (click)="predictData()">
                Predict
              </button>
              <!-- Optimise Button  -->
              <button style="
                      width: 80%;
                      background-color: var(--blue-one);
                      border-radius: 20px;
                      border: none;
                      height: auto;
                      padding: 7px;
                      color: white;
                      cursor: pointer;
                    " (click)="predictData()">
                Optimise
              </button>
  
            </div>
  
          </div>
  
          <!-- KPI Form  -->
  
          <div class="form"
            style="height: 200px; width: 380px; gap:10px; height:150px; display:flex; justify-content:space-around; ">
            <span class="span-text-dark" style="font-weight: bold; text-align: center; font-size: 20px;">KEY PERFORMANCE
              INDICATOR</span>
            <div style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                  gap: 35px;
                  display:flex; justify-content:space-around;
                ">
              <span class="span-text-dark" style="font-size: 14px; font-weight: 500; ">Power Savings</span>
              <div class="input-group" style="width: 100px">
                <input />
                <div class="input-group-formula">%</div>
              </div>
            </div>
  
            <div style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                gap: 11px;
              ">
              <span class="span-text-dark" style="font-size: 14px; font-weight: 500">Optimized Savings</span>
              <div class="input-group" style="width: 100px">
                <input />
                <div class="input-group-formula">%</div>
              </div>
            </div>
          </div>
  
        </div>
  
      </div>
  
  
  
  
    </div>
  </div>